import React from "react";
import {GlobalStyle, MainContainer} from "../style";
import Head from "../components/common/head";
import NavBar from "../components/common/navBar";
import FooterBar from "../components/common/footer";
import PastEvents from "../components/homepage/pastEvents";
import InteractiveEvents from "../components/homepage/interactiveEvents";

const Events = () => {
  return (
    <MainContainer>
      <GlobalStyle/>
      <Head/>
      <NavBar/>
      <InteractiveEvents
        showViewMore={false}
        putSpace={true}
      />
      <PastEvents
        putSpace={true}
        showViewMore={false}
      />
      <FooterBar/>
    </MainContainer>
  );
};

export default Events;